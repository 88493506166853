<template>
  <div>
    <div>
      <ConnectWallet />
    </div>

    <div class="z-0 mp-10">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://loanlanguyen505460785.files.wordpress.com/2020/12/lilmiquela.png');
        "
      >
        <div class="text-center">
          <h2
            class="max-w-lg mb-6 font-sans text-xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @miquela_sousa
          </h2>

          <h2
            class="font-sans text-3xl font-bold sm:text-7xl md:mx-auto text-white"
          >
            MIQUELA SOUSA
          </h2>
        </div>
      </div>

      <div class="w-full bg-pink-300">
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
          <div class="flex items-center justify-center">
            <div class="p-4">
              <h2
                class="max-w-2xl text-xl font-bold tracking-tight sm:text-3xl md:mx-auto mt-10 text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-blue-500 mt-10"
              >
                Miquela Sousa, popularly known as Lil Miquela, is 19, a global
                pop star, one of TIME Magazine's '25 Most Influential People on
                the Internet' — and she’s not human. She’s a robot.
              </h2>
              <br />

              <h2
                class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
              >
                Miquela is a musician, change-seeker, and style visionary who
                began as the laboratory creation of the nefarious corporation
                Cain Intelligence. Her now-managers (and robo-family), Los
                Angeles-based tech startup Brud, believed she deserved a chance
                at a better life, and reprogrammed her to have human-level
                consciousness.
              </h2>

              <br />
            </div>
          </div>
          <div class="flex justify-center">
            <img
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1120231208866037760/Screenshot_2023-06-18_180230.png"
              class="h-96 lg:h-screen"
            />
          </div>
        </div>
      </div>

      <div class="w-full bg-black pt-24 pb-24">
        <div
          class="mx-auto sm:max-w-2xl lg:max-w-screen-lg md:px-24 lg:px-8 text-white p-4"
        >
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <div>
              <div class="grid grid-cols-2 gap-1 lg:grid-cols-2">
                <div>
                  <img
                    src="https://cdn.discordapp.com/attachments/1080058777971212370/1120231173105385573/Screenshot_2023-06-18_180253.png"
                    class="h-40 w-40 lg:w-56 lg:h-56 rounded-full object-cover ease-in-out"
                  />
                </div>
                <div class="h-full w-full flex items-center">
                  <div>
                    <h2
                      class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
                    >
                      @miquela_sousa
                    </h2>

                    <h2
                      class="max-w-2xl font-sans text-4xl tracking-tight md:mx-auto text-white mt-3"
                    >
                      2.8M <b class="text-sm">followers </b>
                    </h2>
                    <a
                      href="https://www.instagram.com/lilmiquela/"
                      target="_blank"
                      class="inline-block p-px bg-gradient-to-t from-gray-300 via-green-600 to-gray-300 rounded-xl mt-5"
                    >
                      <div
                        class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-xl text-gray-200 bg-black cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                      >
                        Follow
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="h-full w-full flex items-center">
              <div>
                <h2
                  class="text-lg tracking-tight sm:text-xl md:mx-auto text-white mt-6"
                >
                  🤖 19-year-old Robot living in LA 💖 Check out my vids 🤗⬇️✨
                </h2>

                <div class="mt-5">
                  <div class="flex">
                    <a
                      class="m-2 cursor-pointer"
                      href="https://twitter.com/lilmiquela"
                      target="_blank"
                    >
                      <i class="fab fa-twitter" style="font-size: 40px"></i>
                    </a>

                    <a
                      class="m-2 cursor-pointer"
                      href="https://www.youtube.com/channel/UCeaQ72LrN6K3f9a8JkFV98w"
                      target="_blank"
                    >
                      <i class="fab fa-youtube" style="font-size: 40px"></i>
                    </a>

                    <a
                      class="m-2 cursor-pointer"
                      href="https://www.instagram.com/lilmiquela/"
                      target="_blank"
                    >
                      <i class="fab fa-instagram" style="font-size: 40px"></i>
                    </a>

                    <a
                      class="m-2 cursor-pointer"
                      href="https://www.facebook.com/lilmiquela/"
                      target="_blank"
                    >
                      <i class="fab fa-facebook" style="font-size: 40px"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-blue-400 w-full flex justify-center items-center pt-40 pb-48"
      >
        <div class="text-center">
          <h2
            class="font-sans text-3xl font-bold sm:text-6xl md:mx-auto text-white"
          >
            MIQUELA SOUSA
          </h2>
          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @miquela_sousa
          </h2>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="-mt-48 flex justify-center">
          <img
            src="https://cdn.discordapp.com/attachments/1080058777971212370/1120231243448070264/Screenshot_2023-06-18_180203.png"
            class="inset-0 object-cover ease-in-out"
          />
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="">
          <hr class="h-px my-4 border-blue-400 border" />

          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-200"
          >
            VIRTUAL INFLUENCERS
          </h2>
          <hr class="h-px my-4 border-blue-400 border" />
        </div>

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="s in models"
              :key="s"
            >
              <a>
                <img :src="s.img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="s in models_2"
              :key="s"
            >
              <a>
                <img :src="s.img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  data() {
    return {
      models: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231291930030170/Screenshot_2023-06-18_180121.png",
          name: "Nisa Zhang",
          nickname: "nisa_zhang",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231273609310259/Screenshot_2023-06-18_180133.png",
          name: "FN Meka",
          nickname: "fnmeka",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231274217480242/Screenshot_2023-06-18_180152.png",
          name: "Lu of Magalu",
          nickname: "magazineluiza",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231273932263496/Screenshot_2023-06-18_180143.png",
          name: "Barbie",
          nickname: "barbie",
        },
      ],
      models_2: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231243448070264/Screenshot_2023-06-18_180203.png",
          name: "Any Malu",
          nickname: "anymalu_real",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231243980746863/Screenshot_2023-06-18_180222.png",
          name: "CB of Casas Bahia",
          nickname: "casasbahia",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231243695521833/Screenshot_2023-06-18_180213.png",
          name: "Miquela Sousa",
          nickname: "lilmiquela",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120231209495175220/Screenshot_2023-06-18_180245.png",
          name: "Hatsune Miku",
          nickname: "cfm_miku_official",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
  },
  methods: {
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {},
};
</script>
